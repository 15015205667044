import theme from '@/theme'
import { Button, ButtonProps, makeStyles } from '@material-ui/core'
import React from 'react'

export interface TCustomBtn {
    fullSize?: boolean
    width?: number
    height?: number
    fontWeight?: number
    fontSize?: number
    colorType?: 'danger' | 'primary'
}

function PrimaryButton({ fullSize = false, classes, colorType, ...props }: ButtonProps & TCustomBtn) {
    const backgroundColor = colorType === 'danger' ? theme.colors.danger : theme.colors.primary
    const useStyle = makeStyles({
        root: {
            width: fullSize ? '100%' : props.width || 'auto',
            height: props.height || 45,
            background: backgroundColor,
            color: theme.colors.white,
            fontFamily: theme.typography.fontFamily,
            fontWeight: props.fontWeight || 300,
            fontSize: props.fontSize || 18,
            borderRadius: '8px',
            '&:hover': {
                background: backgroundColor,
            },
        },
        disabled: {
            backgroundColor: theme.colors.disabledBtn,
        },
    })
    const defaultClasses = useStyle()
    return (
        <Button classes={{ root: defaultClasses.root, disabled: defaultClasses.disabled, ...classes }} {...props}>
            {props.children}
        </Button>
    )
}

export default PrimaryButton
